<template>
  <div class="wheel_wrap">
    <GluecksRad 
      :wheeldata="wheeldata" 
      class="radContainer"
      v-if="showLucky === true" 
      ref="luckyW"
    ></GluecksRad>
  </div>
</template>

<script>
import GluecksRad from "@/components/GluecksRad.vue"

import {mapGetters} from "vuex";
import { mapActions } from "vuex";

export default {
  name: 'WheelFortune',

  components: {
    GluecksRad,
  },

 props: ['projectName'],

  data() {
    return {
      showLucky: false,
    }
  },

  //created() {
   // console.log(' - wheelForune - created !')
  //},

  async mounted() {
    await this.loadLuckyLocal(this.projectName)
    console.log(' - wheelFortuneLocal - mounted !')
    this.showLucky = true;
  },

  computed: {
     ...mapGetters([
       'wheeldata',
    ]),
  },

  methods: {
     ...mapActions([
      'loadLuckyLocal',
    ]),

  }
}
</script>

<style scoped>
</style>
